// import { Platform } from 'react-native';

export const APP_STORE_LINK = 'https://apps.apple.com/app/id6476424527';
export const LOGGEDIN_PATH = '/home';
export const SIGNUP_PATH = '/signup';
export const SIGNIN_PATH = '/login';
export const TRIAL_LESSON_ID =
  process.env.NODE_ENV === 'development' ? 106 : 325;

export const TRIAL_LESSON_PATH = `/start/cc/${TRIAL_LESSON_ID}`;
// export const TODAY_LESSON_PATH = `/today`;

export const LESSON_CC_BASE = {
  url: `${process.env.NEXT_PUBLIC_APP_URI}/start/cc/`,
  path: '/start/cc/',
};

// export const COURSE_BASE = {
//   url: `${process.env.NEXT_PUBLIC_APP_URI}/courses/`,
//   path: '/courses/',
// };

export const PATH_BASE = {
  url: `${process.env.NEXT_PUBLIC_APP_URI}/paths/`,
  path: '/paths/',
};

export const LEXICON_WIKI_BASE = {
  url: `${process.env.NEXT_PUBLIC_APP_URI}/wiki/`,
  path: '/wiki/',
};
